.page-header {
    margin-top: 40px;
}

.ds-logo {
    $w: 612px;
    $h: 920px;
    $ratio: 0.15;

    display: block;
    height: round($h * $ratio);
    width: round($w * $ratio);
    background: url('../logo.svg') 50% 50% no-repeat transparent;
    margin: 10px 0 -55px 10px;
    z-index: 1000;
}

.phone-login {
    font-size: $font-size-base * 0.75;
    span {
        display: inline-block;
        font-weight: bold;
    }
}

.navbar-toggle {
    padding: 0;
    position: absolute;
    right: 10px;
    top: 20px;

    i {
        padding: 10px;
        border-radius: 3px;
    }
}

.slogan-content {
    .slogan-title {
        display: block;
    }
}

.navbar-collapse {
    > .menu {
        padding-top: 60px;
    }
}

.get-started {
    margin-top: 20px;
}

.btn-primary {
    @extend .btn-color;
}

#footer,
.background-animals {
    background: url('../img/footer-bg.jpg') 50% 50% no-repeat fixed;
    background-size: cover;
}

#footer {
    margin-top: 30px;

    h3 {
        text-align: center;
        margin-bottom: 5px;
    }

    .wow {
        margin-bottom: 30px;
        text-align: center;

        .contact-us {
            text-align: left;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.background-green {
    background-color: $brand-success;
}

.breadcrumb-wrapper {
    .pattern-overlay {
        padding-top: 30px;
    }

    .breadcrumb {
        background-color: transparent;
        color: #fff;
        text-align: center;
        padding-left: 0;

        a {
            color: #fff;
            font-weight: bold;
        }

        > li {
            &:first-child {
                &:before {
                    content: "You are here: ";
                    display: inline-block;
                    padding-right: 15px;
                }
            }
        }

        > .active {
            color: #fff;
        }
    }

    margin-bottom: 40px;
}

.arrow-logo {
    background: url('../img/arrow.svg') 0 0 no-repeat;
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
}

.credits {
    font-size: $font-size-base * 0.9;
}

.getdirections-map {
    width: 70%;
}

#getdirections_directions {
    width: auto;
}

.view-sponsors {
    @extend .row;

    .sponsor {

        .wrapper {
            padding: 10px;
            position: relative;

            .border-bottom {
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;

                &.border-bottom-2 {
                    bottom: 2px;
                }

                &.border-bottom-3 {
                    bottom: 5px;
                }
            }
        }

        .logo {
            height: 140px;
            text-align: center;

            a {
                display: block;
                text-align: center;
            }

            img {
                margin: 0 auto;
            }

        }

        .title {
            font-size: $font-size-h2;
            line-height: $font-size-h2;
            margin-bottom: 20px;
        }

        .description {
            padding-bottom: 50px;
        }

        .button {
            position: absolute;
            bottom: 16px;
            left: 0;
            right: 0;
        }
    }
}

.grid {
    figure {
        width: 32.33%;
    }
}

.breadcrumb {
    padding-left: 120px;
}

.gallery {
    .image {
        margin-bottom: $grid-gutter-width;
    }
}

.region-post-content {
    margin-top: 40px;
}

figure {
    &.effect-zoe {
        figcaption {
            transform: translate3d(0, 0, 0);

            >h2,
            >span {
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

#block-views-photo-galleries-block {
    .view-content {
        @extend .row;
    }

    &.portfolio-three-column {
        .grid {
            figure {
                height: 170px;
            }
        }
    }
}

#block-views-shows-block {
    .view-content {
        @extend .row;
    }
}

//contact
#node-8 {
    @extend .row;

    .field-name-body {
        @extend .col-xs-12;
        @extend .col-sm-4;
        @extend .col-sm-push-8;
    }

    .webform-client-form {
        @extend .col-xs-12;
        @extend .col-sm-8;
        @extend .col-sm-pull-4;
    }
}

.flexslider {
    margin: 0;
    border: 0 none;
}




@media (min-width: $screen-xs-min) {
    .phone-login {
        font-size: $font-size-base;
    }
}

@media (min-width: $screen-sm-min) {
    #footer {
        h3 {
            text-align: left;
        }

        .wow {
            text-align: left;
        }
    }

    .ds-logo {
        position: absolute;
    }

    .navbar-collapse > .menu {
        padding-top: 160px;
    }

    .fluid-small {
        width: $screen-sm-min;
    }

    .breadcrumb-wrapper {
        .breadcrumb {
            text-align: right;
        }
    }

    .arrow-logo {
        margin-left: 0;
    }

    .grid {
        figure {
            height: 200px;
        }
    }
}

@media (min-width: $screen-md-min) {
    .navbar-collapse > .menu {
        padding-top: 0;
    }

    .fluid-small {
        width: $screen-md-min;
    }

    .get-started {
        margin-top: 0;
    }
}

@media (min-width: $screen-lg-min) {
    .fluid-small {
        width: $screen-lg-min;
    }
}